export const actionType = {
  isLoading: "SET_LOADING",
  sliderSet: "SET_SLIDER",
  isError: "API_ERROR",
};

const initialState = {
  isSliderLoading: false,
  isError: false,
  slider: [],
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.isLoading:
      return {
        ...state,
        isSliderLoading: true,
      };

    case actionType.sliderSet:
      return {
        ...state,
        isSliderLoading: false,
        slider: action.payload,
      };
    case actionType.isError:
      return {
        ...state,
        isSliderLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
