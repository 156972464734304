import React, { useEffect } from "react";

import ALink from "../components/common/ALink";
import { wordRotate } from "../utils";

export default function AboutUs() {
  useEffect(() => {
    if (document.querySelector(".wort-rotator")) wordRotate();
  }, []);

  return (
    <main className="main about">
      <div className="container">
        <nav aria-label="breadcrumb" className="breadcrumb-nav mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <ALink href="/">Home</ALink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              About Us
            </li>
          </ol>
        </nav>

        <div className="section-2">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <h4 className="about-section-title">Who We Are</h4>

              <p className="mb-2">
                TELECART MART is owned and operated Mr.FARAZ HUMAIR YAR KHAN is
                respected in the business IN PAKISTAN he has built a company
                that constantly strives towards developing a deep understanding
                of customer needs and satisfying them with the right products
              </p>
            </div>
          </div>
        </div>

        <hr className="mt-4 mb-4" />
        <div className="section-2">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <h4 className="about-section-title">OUR MISSION</h4>

              <p className="mb-2">
                TELECART MART, we research, identify and make available new
                products and categories that suit the everyday needs of the
                Pakistani familys. Our mission is to provide the best quality of
                goods for our customers
              </p>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-4" />

        {/* <hr className="mt-4 mb-4" />

        <div className="history-section">
          <h4 className="about-section-title">Our History</h4>

          <div className="history">
            <figure className="thumb d-none d-sm-block">
              <img
                src="images/home/about/office-1.jpg"
                width="145"
                height="145"
                alt="Office Img"
              />
            </figure>
            <div className="featured-box">
              <div className="box-content">
                <h4 className="heading-primary">
                  <strong>2021</strong>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur pellentesque neque eget diam posuere porta. Quisque
                  ut nulla at nunc vehicula lacinia. Proin adipiscing porta
                  tellus, Curabitur pellentesque neque eget diam posuere porta.
                  Quisque ut nulla at nunc vehicula lacinia. Proin adipiscing
                  porta tellus,
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </main>
  );
}
