import { connect } from "react-redux";

import SlideToggle from "react-slide-toggle";
import ALink from "../../src/components/common/ALink";
import { getCartTotal } from "../../src/utils";
import { useCartContext } from "../context/cartContext";
import { useEffect, useState } from "react";
import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router";

const CheckOutScreen = () => {
  const navigate = useNavigate();

  const {
    cart,
    paymentGateway,
    paymentMethods,
    placedOrder,
    placedOrderStatus,
  } = useCartContext();
  const { isLogin, userProfile } = useUserContext();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [zipCode, setZipCode] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [notes, setNotes] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(1);

  useEffect(() => {
    if (isLogin === false) {
      navigate("/login");
    }

    paymentGateway();
  }, [isLogin]);

  useEffect(() => {
    if (placedOrderStatus === true) {
      navigate("/thank-you-for-order");
    }
  }, [placedOrderStatus]);

  useEffect(() => {
    if (isLogin === true) {
      if (typeof userProfile != "undefined" && userProfile !== null) {
        setFirstName(userProfile.name);
        setLastName(userProfile.last_name);
        setCity(userProfile.city);
        setEmail(userProfile.email);
        setPhone(userProfile.phone);
        setZipCode(userProfile.postal_code);
        setStreetAddress(userProfile.shipping_address);
        setCountry(userProfile.country_id);
      }
    }
  }, [userProfile]);

  const placeOrderHandler = (e) => {
    e.preventDefault();
    // const order_detail = {};
    // if (typeof userProfile != "undefined" && userProfile !== null) {
    //   order_detail["customer_id"] = userProfile.id;
    // }
    const  order_detail = {
      customer_id:userProfile.id,
      firstName,
      lastName,
      country,
      streetAddress,
      city,
      zipCode,
      phone,
      email,
      notes,
      orderDetail: cart,
      paymentMethod,
    };

    placedOrder(order_detail);
    return;
  };
  return (
    <main className="main main-test">
      <div className="container checkout-container">
        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
          <li>
            <ALink href="/cart">Shopping Cart </ALink>
          </li>
          <li className="active">
            <ALink href="/checkout">Checkout</ALink>
          </li>
          <li className="disabled">
            <ALink href="#">Order Complete</ALink>
          </li>
        </ul>
        {cart.length === 0 ? (
          <div className="cart-empty-page text-center">
            <p className="noproduct-msg mb-2">
              Checkout is not available while your cart is empty.
            </p>
            <i className="icon-bag-2"></i>
            <p>No products added to the cart</p>
            <ALink
              href="/products"
              className="btn btn-dark btn-add-cart product-type-simple btn-shop font1"
            >
              return to shop
            </ALink>
          </div>
        ) : (
          <>
            {/* <div className="checkout-discount">
              <SlideToggle duration={300} collapsed>
                {({ onToggle, setCollapsibleElement, toggleState }) => (
                  <h4>
                    Returning customer?{" "}
                    <button
                      className="btn btn-link btn-toggle"
                      onClick={onToggle}
                    >
                      Login
                    </button>
                    <div
                      className="login-form-container"
                      ref={setCollapsibleElement}
                      style={{ overflow: "hidden" }}
                    >
                      <div className="login-section feature-box">
                        <div className="feature-box-content">
                          <form action="#" id="login-form">
                            <p className="ls-0">
                              If you have shopped with us before, please enter
                              your details below. If you are a new customer,
                              please proceed to the Billing & Shipping section.
                            </p>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-0 pb-1">
                                    Username or email{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="mb-0 pb-1">
                                    Password <span className="required">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <button type="submit" className="btn">
                              LOGIN
                            </button>

                            <div className="form-footer mb-1">
                              <div className="custom-control custom-checkbox mb-0 mt-0">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="lost-password"
                                />
                                <label
                                  className="custom-control-label mb-0"
                                  htmlFor="lost-password"
                                >
                                  Remember me
                                </label>
                              </div>

                              <ALink
                                href="forgot-password"
                                className="forget-password ls-0"
                              >
                                Lost your password?
                              </ALink>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </h4>
                )}
              </SlideToggle>
            </div> */}
            {/* <div className="checkout-discount">
              <SlideToggle duration={200} collapsed>
                {({ onToggle, setCollapsibleElement, toggleState }) => (
                  <h4>
                    Have a coupon?{" "}
                    <button
                      className="btn btn-link btn-toggle"
                      onClick={onToggle}
                    >
                      ENTER YOUR CODE
                    </button>
                    <div
                      className="feature-box feature-coupon"
                      ref={setCollapsibleElement}
                      style={{ overflow: "hidden" }}
                    >
                      <div className="feature-box-content">
                        <p className="ls-0">
                          If you have a coupon code, please apply it below.
                        </p>

                        <form action="#">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control htmlForm-control-sm w-auto"
                              placeholder="Coupon code"
                              required
                            />
                            <div className="input-group-append">
                              <button className="btn btn-sm mt-0" type="submit">
                                Apply Coupon
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </h4>
                )}
              </SlideToggle>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <ul className="checkout-steps">
                  <li>
                    <h2 className="step-title">Billing details</h2>

                    <form
                      action="#"
                      id="checkout-form"
                      onSubmit={(e) => placeOrderHandler(e)}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              First name{" "}
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              onChange={(txt) => setFirstName(txt.target.value)}
                              value={firstName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Last name
                              <abbr className="required" title="required">
                                *
                              </abbr>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              onChange={(txt) => setLastName(txt.target.value)}
                              value={lastName}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="select-custom">
                        <label>
                          Country{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <select
                          name="orderby"
                          className="form-control"
                          onChange={(e) => setCountry(parseInt(e.target.value))}
                        >
                          <option value="" defaultValue="selected">
                            select
                          </option>
                          <option
                            value="1"
                            selected={country == 1 ? true : false}
                          >
                            Pakistan
                          </option>
                        </select>
                      </div>

                      <div className="form-group mb-1 pb-2">
                        <label>
                          Street address{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="House number and street name"
                          required
                          onChange={(txt) => setStreetAddress(txt.target.value)}
                          value={streetAddress}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Town / City{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          onChange={(txt) => setCity(txt.target.value)}
                          value={city}
                        />
                      </div>

                      <div className="form-group">
                        <label>Postcode / Zip </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(txt) => setZipCode(txt.target.value)}
                          value={zipCode}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Phone{" "}
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          required
                          onChange={(txt) => setPhone(txt.target.value)}
                          value={phone}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Email address
                          <abbr className="required" title="required">
                            *
                          </abbr>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          required
                          onChange={(txt) => setEmail(txt.target.value)}
                          value={email}
                        />
                      </div>
                      {/* <SlideToggle duration={200} collapsed>
                        {({ onToggle, setCollapsibleElement }) => (
                          <div className="form-group mb-1">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="create-account"
                                onChange={onToggle}
                                onClick={(txt) =>
                                  setAccountCreate(txt.target.checked)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="create-account"
                              >
                                Create an account?
                              </label>
                            </div>
                            <div
                              className="form-group"
                              ref={setCollapsibleElement}
                              style={{ overflow: "hidden" }}
                            >
                              <label className="mt-14">
                                Create account password{" "}
                                <abbr className="required" title="required">
                                  *
                                </abbr>
                              </label>
                              <input
                                type="password"
                                placeholder="Password"
                                className="form-control"
                                required={accountCreate}
                                onChange={(txt) =>
                                  setPassword(txt.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                      </SlideToggle> */}

                      <div className="form-group">
                        <label className="order-comments">
                          Order notes (optional)
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Notes about your order, e.g. special notes for delivery."
                          onChange={(txt) => setNotes(txt.target.value)}
                          value={notes}
                        ></textarea>
                      </div>
                    </form>
                  </li>
                </ul>
              </div>

              <div className="col-lg-5">
                <div className="order-summary">
                  <h3>YOUR ORDER</h3>

                  <table className="table table-mini-cart">
                    <thead>
                      <tr>
                        <th colSpan="2">Product</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item, index) => (
                        <tr key={"checks" + index}>
                          <td className="product-col">
                            <h2 className="product-title">
                              {item.name + "×" + item.qty}
                            </h2>
                          </td>

                          <td className="price-col">
                            <span>
                              {process.env.React_APP_CURRENCY_SYMBOLS}
                              {item.price.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className="cart-subtotal">
                        <td>
                          <h4>Subtotal</h4>
                        </td>

                        <td className="price-col">
                          <span>
                            {process.env.React_APP_CURRENCY_SYMBOLS}
                            {getCartTotal(cart).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                      <tr className="order-total">
                        <td>
                          <h4>Total</h4>
                        </td>
                        <td>
                          <b className="total-price">
                            <span>
                              {process.env.React_APP_CURRENCY_SYMBOLS}
                              {getCartTotal(cart).toFixed(2)}
                            </span>
                          </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <div className="payment-methods">
                    <h4 className="">Payment methods</h4>
                    {typeof paymentMethods["paymentsMethod"] !== "undefined" &&
                    paymentMethods["paymentsMethod"].length > 0 ? (
                      <div className="form-group form-group-custom-control">
                        {paymentMethods["paymentsMethod"]?.map((v, i) => (
                          <div className="custom-control custom-radio d-flex">
                            <input
                              type="radio"
                              className="custom-control-input"
                              name="radio"
                              defaultChecked
                            />
                            <label className="custom-control-label">
                              {v.type}
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="info-box with-icon p-0">
                        <p>
                          Sorry, it seems that there are no available payment
                          methods for your state. Please contact us if you
                          require assistance or wish to make alternate
                          arrangements.
                        </p>
                      </div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-dark btn-place-order"
                    form="checkout-form"
                  >
                    Place order
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    cartList: state.cartlist.cart ? state.cartlist.cart : [],
  };
};

export default CheckOutScreen;
