import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/layout";
import HomeScreen from "./pages/home";
import "./assets/sass/style.scss";
import Checkout from "./pages/checkout";
import ProductDetailScreen from "./pages/product_detail";
import ProductScreen from "./pages/products";
import CartScreen from "./pages/cart";
import CheckOutScreen from "./pages/checkout";
import LoginScreen from "./pages/login";
import Wishlist from "./pages/wishlist";
import PageNotFound from "./pages/404";
import ThankForOrder from "./pages/thank-for-order";
import axios from "axios";
import AboutUs from "./pages/about-us";
function App() {
  axios.defaults.withCredentials = true;
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path={"*"} element={<PageNotFound />} />
          <Route exact path="/404" index element={<PageNotFound />} />
          <Route exact path="/" index element={<HomeScreen />} />
          <Route path="/products/" element={<ProductScreen />} />
          <Route path="/about-us/" element={<AboutUs/>} />
          
          <Route path="/wishlist/" element={<Wishlist />} />

          <Route path="/product/:slug" element={<ProductDetailScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/checkout" element={<CheckOutScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/thank-you-for-order" element={<ThankForOrder/>} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
