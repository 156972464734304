import axios from "axios";
import { useContext, useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import reducer, { actionType } from "../reducer/reducerSettings";

const SettingsContext = createContext();

const initialState = {
  isSliderLoading: false,
  isError: false,
  slider: [],
};
const SettingsProvider = ({ children }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSliders = async () => {
    dispatch({ type: "SET_LOADING" });
    try {
      const result = await axios.get(API_URL + "get_all_slider");
      const data = await result.data.data;
      dispatch({ type: actionType.sliderSet, payload: data });
    } catch (error) {
      
      dispatch({ type: actionType.isError });
    }
  };

  useEffect(async () => {
    await getSliders();
  }, []);
  return (
    <SettingsContext.Provider value={{ ...state }}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettingsContext = () => {
  return useContext(SettingsContext);
};

export { SettingsProvider, SettingsContext, useSettingsContext };
