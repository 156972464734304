import { useEffect, useReducer } from "react";
import { useContext, createContext } from "react";
import reducer, { actionTypes } from "../reducer/reducerCart";
import axios from "axios";
import { toast } from "react-toastify";
import AddToCartPopup from "../components/features/modals/add-to-cart-popup";

const CartContext = createContext();

const getCartData = () => {
  var cart_data = localStorage.getItem("tele-cart");

  if (
    typeof cart_data === "undefined" ||
    cart_data === "undefined" ||
    cart_data === null ||
    cart_data.length === 0
  ) {
    return [];
  } else {
    return JSON.parse(cart_data);
  }
};
const initialState = {
  cart: getCartData(),
  totalItem: "",
  totalAmount: "",
  shippingFee: 0,
  paymentMethods: [],
  placedOrderStatus: false,
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem("tele-cart", JSON.stringify(state.cart));
  }, [state.cart]);

  const addToCart = (product, qty, variant) => {
    dispatch({
      type: actionTypes.AddToCart,
      payload: { variant, qty, product },
    });
    toast(<AddToCartPopup product={{ product }} />);
  };

  const updateCart = (product) => {
    dispatch({
      type: actionTypes.UpdateCart,
      payload: { product },
    });
  };

  const removeToCart = (product) => {
    dispatch({
      type: actionTypes.RemoveFromCart,
      payload: { product },
    });
  };
  const removeCartItems = (product) => {
    dispatch({
      type: actionTypes.RemoveCartItems,
      payload: { product },
    });
  };

  const addToWishList = (product, qty, variant) => {
    dispatch({
      type: actionTypes.AddToCart,
      payload: { variant, qty, product },
    });
  };

  const paymentGateway = () => {
    const paymentsMethod = [
      { id: 1, type: "COD" },
      { id: 2, type: "Advance Payment" },
    ];

    dispatch({
      type: actionTypes.PaymentMethods,
      payload: { paymentsMethod },
    });
  };

  const placedOrder = async (postData) => {
    try {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      };
      const result = await axios.post(
        process.env.React_APP_API_ENDPOINT + "order_place",
        postData,
        headers
      );
      const data = result.data;
      if (data.status === 200 && data.success === true) {
        dispatch({ type: actionTypes.orderPlaced, payload: data.data });
      }
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        addToCart,
        addToWishList,
        updateCart,
        removeToCart,
        paymentGateway,
        placedOrder,
        removeCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(CartContext);
};

export { CartProvider, CartContext, useCartContext };
