import { useEffect, useState } from "react";

// Import Custom Component
import NewsletterModal from "../components/features/modals/newsletter-modal";
import LoadMoreProducts from "../components/partials/home/load-more-product";
import TopRatedCollection from "../components/partials/home/top-product-collection";
import IntroSection from "../components/partials/home/intro-section";
import BannerSection from "../components/partials/home/banner-section";
import FeatureBoxSection from "../components/partials/home/featurebox-section";
import CategroySection from "../components/partials/home/caty-section";
import PromoSection from "../components/partials/home/promo-section";
import ProductWidgetContainer from "../components/partials/home/product-widget-container";
import BlogSection from "../components/partials/home/blog-section";
import BrandSection from "../components/partials/home/brand-section";
import { GetAllProduct } from "../query";
import { useProductContext } from "../context/productContext";
import { useSettingsContext } from "../context/SettingsContext";
const HomeScreen = () => {
  const { isLoading, featureProduct, hotProduct ,newProduct,topRated } = useProductContext();
  
  
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const error = [];
  const data = [];
  const bestSelling = []; //data && data.specialProducts.bestSelling;
  const latest = []; //data && data.specialProducts.latest;
  const total = 0; //data && data.products.total;
  const blog = []; //data && data.posts.data;

  if (error.length > 0) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      <main
        className={`home skeleton-body skel-shop-products ${
          loading ? "" : "loaded"
        }`}
      >
        <IntroSection />
        <BannerSection />

        <FeatureBoxSection />
        <div className="container mb-4 mb-lg-6">
          <LoadMoreProducts
            heading="New Products"
            product={newProduct}
            loading={isLoading}
            perPage={5}
            total={total}
          />
        </div>

        <hr className="mb-4 pb-1" />
        
        <div className="container mb-4 mb-lg-6">
          <LoadMoreProducts
            heading="Featured Products"
            product={featureProduct}
            loading={isLoading}
            perPage={5}
            total={total}
          />
        </div>
        <hr className="mb-4 pb-1" />
        {/* Hot Products */}
        <div className="container mb-4 mb-lg-6">
          <LoadMoreProducts
            heading="Hot Products"
            product={hotProduct}
            loading={isLoading}
            perPage={5}
            total={total}
          />
        </div>

        <hr className="mb-4 pb-1" />
        {/*
        <div className="container mb-4 mb-lg-6">
          <CategroySection />
        </div> 

        <PromoSection /> 
         */}

        {/* <div className="container mb-2 mb-lg-4">
          <TopRatedCollection product={topRated} loading={isLoading} />

          <hr className="mt-1 m-b-5" />

          <ProductWidgetContainer
            featured={featureProduct}
            latest={latest}
            bestSelling={bestSelling}
            topRated={topRated}
            loading={isLoading}
          />
        </div> */}
        <BrandSection />
      </main>
      {/* <NewsletterModal /> */}
    </>
  );
};

export default HomeScreen;
