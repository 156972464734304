import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.min.css";
import "rc-tree/assets/index.css";
import "react-input-range/lib/css/index.css";
import "react-image-lightbox/style.css";

import StickyNavbar from "../components/common/partials/sticky-navbar";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import MobileMenu from "../components/common/partials/mobile-menu";
//import QuickModal from "../components/features/modals/quickview";
// import VideoModal from "../components/features/modals/video-modal";

import { actions } from "../store/modal";
import { stickyInit, scrollTopHandlder, scrollTopInit } from "../utils";
import { base_url } from "../pages/constants/constants";
import { Outlet } from "react-router";
import { useCartContext } from "../context/cartContext";

import "../assets/css/whatsapp_button_flot_button.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
const Layout = ({ children, hideQuickView, hideVideo }) => {
  const { cart } = useCartContext();

  useEffect(() => {
    window.addEventListener("scroll", stickyInit, { passive: true });
    window.addEventListener("scroll", scrollTopInit, { passive: true });
    window.addEventListener("resize", stickyInit);
    // hideQuickView();
    // hideVideo();

    return () => {
      window.removeEventListener("scroll", stickyInit, { passive: true });
      window.removeEventListener("scroll", scrollTopInit, { passive: true });
      window.removeEventListener("resize", stickyInit);
    };
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Header />

        <Outlet />

        <Footer />
        {/* <a
          href="https://wa.me/+923130871736"
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a> */}

        <FloatingWhatsApp phoneNumber="923130871736" accountName="Telecart Admin" avatar="https://telecartmart.com/assets/images/logo-white.png" />
        <ToastContainer
          autoClose={3000}
          duration={300}
          newestOnTo={true}
          className="toast-container"
          position="bottom-right"
          closeButton={false}
          hideProgressBar={true}
          newestOnTop={true}
          draggable={false}
        />

        {/* <QuickModal /> */}
        {/* <VideoModal /> */}

        <div className="wishlist-popup">
          <div className="wishlist-popup-msg">Product added!</div>
        </div>
      </div>

      <MobileMenu />
      <StickyNavbar cartItems={cart} />

      <a
        id="scroll-top"
        href="#"
        title="Top"
        role="button"
        className="btn-scroll"
        onClick={scrollTopHandlder}
      >
        <i className="icon-angle-up"></i>
      </a>
    </>
  );
};

export default Layout;
